<!-- eslint-disable global-require -->
<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col
          cols="12"
          class="py-2"
        >
          <span class="px-2">Status of this employee is:</span>
          <b-btn
            variant="dark"
            disabled
          > approve </b-btn>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="role"
            rules="required"
          >
            <b-form-group
              label="Role"
              label-for="Role"
            >
              <b-form-select
                id="role"
                v-model="approveEmployees.role"
                :options="optionsRoles"
                trim
                placeholder="role"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="office_id"
            rules="required"
          >
            <b-form-group
              label="office"
              label-for="Office"
            >
              <b-form-select
                id="role"
                v-model="approveEmployees.office_id"
                :options="optionsOffice"
                trim
                placeholder="role"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="errorMsg"
          cols="12"
        >
          <p class="text-danger">
            {{ errorMsg }}
          </p>
        </b-col>
        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addemployees()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addemployees()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'

import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { extend } from 'vee-validate'
import decryptData from '@/store/auth/decryptData'

extend('url', {
  validate: value => {
    // Regular expression for a simple URL validation
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    return urlRegex.test(value)
  },
  message: 'Please enter a valid URL.',
})
export default {
  components: {},
  mixins: [togglePasswordVisibility],

  data() {
    return {
      loading: false,
      userLocation: {},
      position: {},
      branches: [],
      companies: [],
      optionsBranch: [],
      errorMsg: '',
      required,
      id: this.$store.state.generalIds.id,
      selected: [],
      allSelected: [],
      indeterminate: false,
      allPermissions: [],
      users: [],
      loader: false,
      validationErrors: {},
      errors: {},
      optionsRoles: [],
      optionsOffice: [],
      passwordFieldType: 'text',
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const approveEmployees = ref({
      office_id: '',
    })
    // eslint-disable-next-line camelcase

    return {
      getValidationState,
      approveEmployees,

      // photo,
    }
  },
  mounted() {
    this.getRole()
    this.getOffice()
    this.showemployees()
    // console.log(this.$route.query.ofice)
  },
  methods: {
    getOffice() {
      this.loadtable = true
      axios.get('office').then(res => {
        this.optionsOffice = decryptData(res.data.payload).data.offices.map(item => ({
          value: item.id,
          text: item.name,
        }))
      }).finally(() => {
        this.loadtable = false
      })
    },
    getRole() {
      this.loadtable = true
      Promise.all([
        axios.get('register/site/roles'),
        axios.get('register/panel/roles'),
      ])
        .then(([siteRolesResponse, adminRolesResponse]) => {
          const siteRoles = decryptData(siteRolesResponse.data.payload).data.roles
          const adminRoles = decryptData(adminRolesResponse.data.payload).data.roles

          // Combine the roles from both responses
          const allRoles = [...siteRoles, ...adminRoles]

          // Map to the desired format
          this.optionsRoles = allRoles.map(item => ({
            value: item.name,
            text: item.name,
          }))
        })
        .finally(() => {
          this.loadtable = false
        })
    },

    showemployees() {
      if (this.$route.params.id) {
        this.approveEmployees.office_id = this.$route.query.ofice
      } else {
        return false
      }
      return true
    },
    addemployees() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.approveEmployees) {
          formData.append(key, this.approveEmployees[key])
        }
        formData.append('approve', 1)

        // formData.append('company_brief', 'company brief')
        axios
          .post(`employees/${this.$route.params.id}`, formData)
          .then(res => {
            if (res.status === 200) {
              this.$router.push({ name: 'employees' })
              this.$toasted.show('employee added', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            if (error.response.status === 500) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ooops!!! An Unexpected Internal server error',
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
            } else if (error.response.status === 422) {
              if (error.response.data.errors !== undefined) {
                this.errorMsg = error.response.data.errors
              } else {
                this.errorMsg = error.response.data.message
              }
            }
          })
          .finally(() => {
            this.loader = false
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
